export const studentSearch = (tab) => ({
  tab,
  params: new URLSearchParams(window.location.search),
  name: null,
  ssn: null,
  phone: null,

  init() {
    this.name = this.params.get('name')
    this.ssn = this.params.get('ssn')
    this.phone = this.params.get('phone')
  },

  search(changedParams = {}) {
    const { params } = this
    params.set('tab', this.tab)

    for (let [key, value] of Object.entries(changedParams)) {
      value ? params.set(key, value) : params.delete(key)
    }

    // Reset to 1st page
    params.delete('active_page')
    params.delete('archived_page')

    // Reload the page with new params
    window.location.assign(`${window.location.pathname}?${params.toString()}`)
  },
})

export const studentSort = (sortBy, sortDirection) => ({
  sortBy,
  sortDirection,

  sort(attribute) {
    const attrChanged = this.sortBy !== attribute
    const oppositeDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
    this.sortBy = attribute
    this.sortDirection = attrChanged ? 'asc' : oppositeDirection

    this.search({ sort_direction: this.sortDirection, sort_by: this.sortBy })
  },
})
