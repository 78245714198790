type Accomplishment = {
  id: number | null
  completedAt: string
  completedPreviously: boolean
  teachingType: string
}

type TestResult = {
  id: number | null
  totalTime: number
  testScore: number | null
  testApproved: boolean
}

type VideoResult = {
  id: number | null
  watchTime: number
  origWatchTime: number
  watchedPercentage: number
  createdAt: string | null
  updatedAt: string | null
}

type Video = {
  id: number
  name: string
  duration: number
}

type VideoData = {
  video: Video
  result: VideoResult
}

export const subjectResults = (
  inAppPurchaseSubjectId: number,
  driverId: number,
  maximumScore: number,
  accomplishment: Accomplishment,
  testResult: TestResult,
  videoData: VideoData[]
) => ({
  maximumScore,
  accomplishment,
  testResult,
  videoData,

  init(): void {
    this.videoData.forEach((data) => this.initVideoResult(data))
  },

  get fullyWatched(): boolean {
    return (this.testResult?.totalTime || 0) >= 98
  },

  get totalVideoDuration(): number {
    return this.videoData.reduce((acc, { video }) => acc + video.duration, 0)
  },

  get totalWatchTime(): number {
    return this.videoData.reduce((acc, { result }) => acc + result.watchTime, 0)
  },

  dateTime(str: string): string {
    const locale = document.querySelector('html')?.getAttribute('lang') || 'fi'
    return new Date(str).toLocaleString(locale, { dateStyle: 'short', timeStyle: 'short' })
  },

  // Set up some attributes to keep track of changes
  initVideoResult({ result, video }: VideoData): void {
    result.origWatchTime = result.watchTime
    result.watchedPercentage = this.watchedPercentage(video.duration, result.watchTime)
  },

  createAccomplishment(): void {
    const { id, msg } = this.$event.detail[0] as { id: number; msg: string }
    this.accomplishment = { id, completedPreviously: true, teachingType: '', completedAt: '' }
    window.flash('notice', msg)
  },

  removeAccomplishment(): void {
    this.accomplishment = { id: null, completedPreviously: false, teachingType: '', completedAt: '' }
    window.flash('notice', this.$event.detail[0].msg)
  },

  removeTestResult(): void {
    this.videoData.forEach((data) => {
      data.result = { id: null, watchTime: 0, origWatchTime: 0, watchedPercentage: 0, createdAt: null, updatedAt: null }
    })

    this.testResult.id = null
    this.testResult.totalTime = 0
    this.testResult.testScore = null
    this.testResult.testApproved = false

    window.flash('notice', this.$event.detail[0].msg)
  },

  async saveVideoResult(result: VideoResult, video: Video): Promise<void> {
    let url = '/in_app_purchase_subject_item_results'
    if (result.id) url += `/${result.id}`

    const data = {
      id: result.id,
      in_app_purchase_subject_id: inAppPurchaseSubjectId,
      driver_id: driverId,
      watch_time: result.watchTime,
      video_id: video.id,
    }

    try {
      const res = await fetch(url, {
        method: result.id ? 'PATCH' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ in_app_purchase_subject_item_result: data }),
      })

      if (!res.ok) throw new Error()

      const {
        result: { id, createdAt, updatedAt },
        subject_result_id,
        msg,
      } = (await res.json()) as { result: VideoResult; subject_result_id: number | undefined; msg: string }
      result.id = id
      result.createdAt = createdAt
      result.updatedAt = updatedAt

      // Re-initialize result to make the created/updated values the new base
      this.initVideoResult({ result, video })

      // Update / create associated test result
      if (subject_result_id) {
        this.testResult.id = subject_result_id
        this.testResult.testScore ||= 0
      }
      this.setTotalWatchedPercentage()

      window.flash('notice', msg)
    } catch (error) {
      alert(window.I18n.unknown_error)
    }
  },

  removeVideoResult(result: VideoResult): void {
    result.id = null
    result.watchTime = 0
    result.origWatchTime = 0
    result.watchedPercentage = 0
    result.createdAt = null
    result.updatedAt = null

    this.setTotalWatchedPercentage()
    window.flash('notice', this.$event.detail[0].msg)
  },

  secondsToString(seconds: number): string {
    const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0')
    const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, '0')
    const s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0')

    return `${h}:${m}:${s}`
  },

  setTotalWatchedPercentage(): void {
    this.testResult.totalTime = this.watchedPercentage(this.totalVideoDuration, this.totalWatchTime)
  },

  watchTime(duration: number, percentage: number | null): number {
    return percentage ? Math.floor((duration / 100.0) * percentage) : 0
  },

  watchedPercentage(duration: number, watchTime: number | null): number {
    const percentage = watchTime ? Math.round((watchTime / duration) * 100) : 0
    return Math.min(100, percentage)
  },
})
