type InAppPurchaseSubject = {
  in_app_purchase_id: number
  need_supervision: boolean
  lesson_type_subject_id: number | null
  lesson_count: number
  driving_test_question_ids: number[]
}

type LessonTypeSubject = {
  id: number
  name: string
}

type InAppPurchase = {
  id: number
  lesson_type_subjects: LessonTypeSubject[]
}

type QuestionData = {
  id: number
  get_answer_score: number
}

export default (inAppPurchaseSubject: InAppPurchaseSubject, inAppPurchases: InAppPurchase[], questionData: QuestionData[]) => ({
  subject: inAppPurchaseSubject,
  inAppPurchases,
  questionData,

  get lessonTypeSubjects(): LessonTypeSubject[] {
    const inAppPurchase = this.inAppPurchases.find(({ id }) => id === this.subject.in_app_purchase_id)
    const subjects = inAppPurchase?.lesson_type_subjects || []

    // Select first radio button when the selectable options change
    if (!subjects.some(({ id }) => id === this.subject.lesson_type_subject_id)) {
      this.subject.lesson_type_subject_id = subjects[0]?.id || null
    }

    return subjects
  },

  get maximumTestScore(): number {
    return this.selectedQuestions.reduce((acc, question) => acc + question.get_answer_score, 0)
  },

  get selectedQuestions(): QuestionData[] {
    return this.questionData.filter(({ id }) => this.subject.driving_test_question_ids.includes(id))
  },
})
